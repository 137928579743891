import React, { useEffect, useRef, useState } from 'react';
import camuflado from "../../../../fundo.webp";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import { Col, Card, Row, Typography,  Select, Form, Skeleton, QRCode, Input, Button, notification, Image, message } from "antd";
import axios from 'axios';
import errorImage from '../../../../escolhaservidor.webp';
import botApiUrl from '../../../../botApiUrl';
import axiosInstance from '../../../../axiosInterceptor';
import InputMask from 'react-input-mask';



const Mensageiro = () => {

  const accessToken = window.localStorage.getItem("accessToken");

  const [loading, setLoading] = useState(false);
  const [loadingQR, setLoadingQR] = useState(false);
  const [loja, setLoja] = useState(null);
  const idUser = window.localStorage.getItem("idUser");
  const [idLoja, setIDLoja] = useState(null);


  const { Option } = Select;
  const [instancias, setInstancias] = useState([]);
  const [selectedInstancia, setSelectedInstancia] = useState(null);
  const [qrCode, setQrcode] = useState('');
  const [linhaEscolhida, setLinhaEscolhida] = useState(null);
  const intervalRef = useRef(null);
  const [reload, setReload] = useState(true);

    useEffect(() => {
      if (idUser && idLoja) {
     //   setLoading(true);

        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };
        axios.get(
       `https://apimsg.techsbot.com.br/api/lojas/3B5A1BB81290D03B60DBCA31E3C9CC81/${loja.dominioLoja}` ,
          options
        ).then((resp)=>{
              const qtdLinhas = loja.linhasTechsbot;
              console.log(qtdLinhas);
              const diff = qtdLinhas - resp.data.length;
              console.log(qtdLinhas);
              const instanciasExtras = [...resp.data];
              if (diff > 0) {
                for (let i = 0; i < diff; i++) {
                 instanciasExtras.push({ id: `${instancias.length + i}`, whatsapp: `Linha livre ${instancias.length + i + 1}` });
                }
              }
              setInstancias(instanciasExtras); 
        console.log(instanciasExtras);
        }).catch((error) => {
          setLoading(false);
        });





     /*   const qtdLinhas = loja.linhasTechsbot;
        const diff = qtdLinhas - 0;



        const instanciasExtras = []
  
          for (let i = 0; i < diff; i++) {
           instanciasExtras.push({ id: `${instancias.length + i}`, tel: `Linha livre ${instancias.length + i + 1}` });
          }
        
        setInstancias(instanciasExtras); 

;*/

      }
    }, [idUser, accessToken, reload, idLoja]);

    useEffect(() => {
      if(idUser){
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };
         axiosInstance.get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}` ,
          options
        ).then((resp)=>{
          setIDLoja(resp.data.id);
          setLoja(resp.data);
        })
  
      }
    }, [idUser]);


  const buscarQr = () =>{
    if (accessToken) {
      setLoadingQR(true);
      console.log('buscando QR');
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      axios.get(
        `https://apimsg.techsbot.com.br/api/qr/3B5A1BB81290D03B60DBCA31E3C9CC81` ,
        options
      ).then((resp)=>{
        console.log(resp.data);
            setQrcode(resp.data.qrcodeString.qr);
            setLoadingQR(false);
      })
    }
  }


  function mostrarQR(id) {
    const linha = instancias.find(item => item._id === id);
    return linha?.clientId ?false:true;
  }

  const formatarTelefone = (phoneNumber) => {
    if(!phoneNumber){return phoneNumber;}
    // Remove todos os caracteres não numéricos
    const cleaned = phoneNumber.replace(/\D/g, '');
  
    // Verifica se o número começa com 55 e tem 12 ou 13 dígitos
    if (cleaned.startsWith('55') && (cleaned.length === 12 || cleaned.length === 13)) {
      const numberWithoutCountryCode = cleaned.substring(2);
  
      if (numberWithoutCountryCode.length === 11) {
        // Formato para número de celular: +55 (xx) xxxxx-xxxx
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{5})(\d{4})$/, '+55 ($1) $2-$3');
      } else if (numberWithoutCountryCode.length === 10) {
        // Formato para número fixo: +55 (xx) xxxx-xxxx
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{4})(\d{4})$/, '+55 ($1) $2-$3');
      } else {
        // Se o número não tiver 10 ou 11 dígitos após o código do país, retorna o número original
        return phoneNumber;
      }
    } else {
      // Se o número não começar com 55 ou não tiver o comprimento correto, retorna o número original
      return phoneNumber;
    }
  };
  useEffect(()=>{
    if(instancias.length > 0){
      handleInstanciaChange(instancias[0].id);
    }
  },[instancias])

    useEffect(() => {
      const resetInterval = () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
          handleInstanciaChange(selectedInstancia);
        }, 30000); 
      };
  
      resetInterval();
  
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      }
    }, [selectedInstancia]);

  const handleInstanciaChange = (value) => {

    const linha = instancias.find(item => item._id === value);

    if(!linha?.clientId){ 
      setLoading(false);
      buscarQr();
    }else{
      setLinhaEscolhida(linha);
    }
    setSelectedInstancia(value);

    console.log(value);
    console.log(linha);
  };

  return (


     <Container>
       <Row
         gutter={16}
         style={{
           justifyContent: "center",
           marginTop: "70px",
           marginBottom: "80px",
         }}
       >
         <Card style={{ width: "95%", margin: "0 auto", padding: "20px",paddingLeft:0, paddingRight:0 }}>
         <Form
         name="basic"
         layout="horizontal"
         initialValues={{ remember: true }}
         onFinish={async (dados)=>{
           try{
            let whatsappApenasNumeros = dados.whatsapp.replace(/\D/g, '');
            console.log(whatsappApenasNumeros);

            const regexTelefone = /^[1-9]{2}\d{8}$/;

            const regexCelular = /^[1-9]{2}9\d{8}$/;
          
            // Verifica se o número corresponde a um dos padrões
            if (!regexTelefone.test(whatsappApenasNumeros) && !regexCelular.test(whatsappApenasNumeros)) {
              message.info('Número inválido para telefone ou celular. Verifique se digitou corretamente.');
              return;
            }else{
              whatsappApenasNumeros =  55 + whatsappApenasNumeros;
            }
    
        
         setLoading(true);
           const options = {
             headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               Authorization: "Bearer " + accessToken,
             },
           };
  
       const response1 = await axiosInstance.put(
            `https://apimsg.techsbot.com.br/api/lojas/whatsapp/${whatsappApenasNumeros}/3B5A1BB81290D03B60DBCA31E3C9CC81`,
 
         {
          dominioLoja: loja.dominioLoja,
          dominioUrl: loja.dominio
        },options
       );
       if(response1.status == 200){
         notification.success({
           message: "Sucesso",
           description: `Instancia Conectada!`,
         });
       
     
         setTimeout(() => {
           window.location.reload();
         }, 2000);
       }
       setLoading(false);
     }
       catch (error) {
         console.error('Erro ao chamar a API:', error.status);
         console.error('Erro ao chamar a API:', error);
         notification.error({
           message: "Falha",
           description: `${error.response.data}`,
         });
         if(error.status == 404){
             console.error(`${error.status} Instancia não encontrada`);
         }
         setLoading(false);
         return ;
     }
         }}
         onFinishFailed={(errorInfo) =>{ console.log('Failed:', errorInfo);}}
         autoComplete="off"
       >
         <Col >
         <Form.Item
        label="WhatsApp"
        name="whatsapp"
        rules={[{ required: true, message: 'Whatsapp obrigatório!' }]}
        style={{ width: '100%' }}
      >
        <InputMask mask="(99) 99999-9999">
          {(inputProps) => <Input {...inputProps} style={{ width: '100%' }} />}
        </InputMask>
      </Form.Item>

      <Button
        style={{ width: '100%', backgroundColor: '#000' }}
        type="primary"
        htmlType="submit"
        loading={loading}
      >
        Confirmar
      </Button>
     </Col>
 
       </Form>
         </Card>
    <Card style={{ width: "95%", margin: "0 auto", padding: "20px",marginTop:'10px' }}>
    <Row gutter={[16, 16]} align="middle">
      <Col xs={24} sm={24} style={{ textAlign: "center" }}>


      {!loading ?  <Form.Item label="Linhas:">
              <Select
                style={{ width: "100%" }}
                placeholder="Selecione uma linha..."
                value={selectedInstancia}
                onChange={handleInstanciaChange}
              >
                {instancias.map((instancia) => (
                  <Option key={instancia._id} value={instancia._id}>
                    {formatarTelefone(instancia.whatsapp)}
                  </Option>
                ))}
              </Select>
            </Form.Item> : <Skeleton></Skeleton>}

      </Col>
      {mostrarQR(selectedInstancia) ? <Col xs={24} sm={24} style={{justifyContent: 'center',display: 'flex'}}>
      {qrCode ? (
<QRCode value={qrCode} size={330} status={loadingQR ? "loading" : "active"} />
      ) : (
        <Image
        width={330}
        height={330}
        src={errorImage} // Use o caminho correto para sua imagem de erro
        alt="Erro de conexão"
        preview={false}
      />
      )}

      </Col> : !loading ? <center style={{width:'100%'}}><strong>Plugin ID: </strong>{`${linhaEscolhida?.idPlugMessage}`} <br/> <strong>Plugin Token: </strong>{`${linhaEscolhida?.tokenPlugMessage}`}<br/>{`${linhaEscolhida?.clientId}`}<br/>{`${formatarTelefone(linhaEscolhida?.whatsapp)}`}<br/>{`${linhaEscolhida?.ativo ? 'Ativo': 'Inativo'}`}</center> : <Skeleton></Skeleton>}

    </Row>
  </Card>

        </Row>
      </Container>
  );
};



const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

export default Mensageiro;
